import React, { useMemo, createContext } from 'react'
import GTM from 'react-gtm-module'
import * as Amplitude from '@amplitude/analytics-browser'

export enum LocalAnalyticsEvent {
  ContinueNextStep = 'WELCOME_CONTINUE_NEXT_STEP',
  FirstPageView = 'WELCOME_FIRST_PAGE_VIEW',
  SecondPageView = 'WELCOME_SECOND_PAGE_VIEW',
  ThirdPageView = 'WELCOME_THIRD_PAGE_VIEW',
  FindTraining = 'WELCOME_FIND_TRAINING',
}

export enum EventCategory {
  Button = 'button',
  Dropdown = 'dropdown',
  Input = 'input',
}

export enum EventAction {
  Click = 'click',
  Select = 'select',
  Entered = 'entered',
}

// Initialize analytics services
GTM.initialize({
  gtmId: import.meta.env.REACT_APP_GTM_TRACKING_ID!,
})
Amplitude.init(import.meta.env.REACT_APP_AMPLITUDE_API_KEY)

interface AnalyticPlatform {
  sendPageView: (screen: string) => void
  sendEvent: (
    event: LocalAnalyticsEvent,
    category: EventCategory,
    action: EventAction,
    params?: { [key: string]: any }
  ) => void
  pushToDatalayer: (params: { [key: string]: any }) => void
}

export const analyticsContext = createContext<AnalyticPlatform>({
  sendEvent: () => {},
  sendPageView: () => {},
  pushToDatalayer: () => {},
})

analyticsContext.displayName = 'analyticsContext'
const { Provider } = analyticsContext

const AnalyticsProvider: React.FC = ({ children }) => {
  const GTMPlatform: AnalyticPlatform = useMemo(
    () => ({
      sendEvent(
        event: LocalAnalyticsEvent,
        category: EventCategory,
        action: EventAction,
        params?: { [key: string]: any }
      ): void {
        try {
          GTM.dataLayer({
            dataLayer: {
              eventParams: undefined,
            },
          })
          GTM.dataLayer({
            dataLayer: {
              event: event.toLowerCase(),
              category,
              action,
              eventParams: params,
            },
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on sendEvent')
          }
        }
      },
      sendPageView(path: string): void {
        try {
          GTM.dataLayer({
            dataLayer: {
              eventParams: undefined,
            },
          })
          GTM.dataLayer({
            dataLayer: {
              event: 'pageview',
              path,
              location: window.location.href,
              title: document.title,
            },
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on sendPageView')
          }
        }
      },
      pushToDatalayer(params: { [key: string]: any }): void {
        try {
          GTM.dataLayer({
            dataLayer: params,
          })
        } catch (err) {
          if (import.meta.env.MODE === 'development') {
            console.warn('Error calling GTM on pushToDatalayer')
          }
        }
      },
    }),
    []
  )
  return <Provider value={GTMPlatform}>{children}</Provider>
}

export default AnalyticsProvider
